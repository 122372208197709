import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import "./header.css"

const CaseStudySection = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(
          relativePath: { eq: "new-images/quandary-casestudy-section-3.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        // fluid={imageData}
        className="hero-banner-case hero-overlay  relative"
        preserveStackingContext
        // style={{ backgroundPosition: "center" }}
        style={{ backgroundPosition: "70% 50%" }}
      >
        <div className="hero-overlay" />
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-9 mr-auto">
              <div className="hero-procurement pb-xs-0">
                {/* <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  WORKATO SOLUTIONS
                </h5> */}
                <h1 className="casestudysection-title text-white font-weight-medium mt-0 mb-4">
                  Leading Port Service Management
                  <br />
                  Company Continuously Optimizes
                  <br />
                  Operations with Low-Code
                </h1>
                {/* <p>
                  Connect workflows, processes, and applications with Workato’s
                  drag and drop interface. (No-Code Required.)
                </p> */}
                <div className="mt-4">
                  <Link
                    aria-label="link"
                    className="btn btn-casestudy mr-2 mt-4 text-center"
                    to="/case-study/leading-port-service-management-company-at-the-port-of-savannah-continuously-optimizes/"
                  >
                    Learn More
                    {/* <i className="ml-2 item-arrow" aria-hidden="true"></i> */}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 p-sm-5"
              style={{ position: "relative" }}
            ></div>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default CaseStudySection
